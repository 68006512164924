import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import $store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    meta: { title: "首页", isAuth: false, tabbar: "module" },
    component: () => import("@/views/Index.vue"),
    redirect: { path: "/gateway" },
    children: [
      {
        path: "gateway",
        component: () => import("@/views/Module.vue"),
        meta: { title: "藏经阁", isAuth: false, tabbar: "module" },
        redirect: { path: "/gateway/module" },
        children: [
          {
            path: "module",
            component: () => import("@/views/module_view/Gateway.vue"),
            meta: { title: "藏经阁", isAuth: false, tabbar: "module" },
          },
          {
            path: "question",
            component: () => import("@/views/module_view/Question.vue"),
            meta: { title: "知识问答", isAuth: false, tabbar: "question" },
          },
          {
            path: "user",
            component: () => import("@/views/module_view/User.vue"),
            meta: { title: "我的", isAuth: false, tabbar: "user" },
          },
        ],
      },
      {
        path: "testPage",
        component: () => import("@/views/module_view/model/testPage.vue"),
        meta: { title: "测试页面", isAuth: false, tabbar: "module" },
      },
      {
        path: "enterpriseFiles",
        component: () =>
          import("@/views/module_view/model/EnterpriseFiles.vue"),
        meta: { title: "企业文件", isAuth: false, tabbar: "module" },
      },
      {
        path: "Search",
        component: () => import("@/views/module_view/model/Search.vue"),
        meta: { title: "搜索", isAuth: false, tabbar: "module" },
      },
      {
        path: "CheckFile",
        component: () => import("@/views/module_view/model/CheckFile.vue"),
        meta: { title: "文件详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "specification",
        component: () => import("@/views/module_view/model/Specification.vue"),
        meta: { title: "标准规范", isAuth: false, tabbar: "module" },
      },
      {
        path: "StandardAtlas",
        component: () => import("@/views/module_view/model/StandardAtlas.vue"),
        meta: { title: "标准图集", isAuth: false, tabbar: "module" },
      },
      {
        path: "LawPage",
        component: () => import("@/views/module_view/model/LawPage.vue"),
        meta: { title: "法律法规", isAuth: false, tabbar: "module" },
      },
      {
        path: "specificationInfo",
        component: () =>
          import("@/views/module_view/model/Specification_info.vue"),
        meta: { title: "详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "specificationInfoAtlas",
        component: () =>
          import("@/views/module_view/model/SpecificationAtlas_info.vue"),
        meta: { title: "图集详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "lawPageInfo",
        component: () => import("@/views/module_view/model/LawPage_info.vue"),
        meta: { title: "法律详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "validation",
        component: () => import("@/views/module_view/model/Validation.vue"),
        meta: { title: "规范验证", isAuth: false, tabbar: "module" },
      },
      {
        path: "validationStandardAtlas",
        component: () =>
          import("@/views/module_view/model/ValidationStandardAtlas.vue"),
        meta: { title: "图集验证", isAuth: false, tabbar: "module" },
      },
      {
        path: "validationResult",
        component: () =>
          import("@/views/module_view/model/ValidationResult.vue"),
        meta: { title: "验证结果", isAuth: false, tabbar: "module" },
      },
      {
        path: "validationResultAtlas",
        component: () =>
          import("@/views/module_view/model/ValidationResultAtlas.vue"),
        meta: { title: "图集验证结果", isAuth: false, tabbar: "module" },
      },
      {
        path: "SpecificationVerification",
        component: () =>
          import("@/views/module_view/model/SpecificationVerification.vue"),
        meta: { title: "规范验证结果", isAuth: false, tabbar: "module" },
      },
      {
        path: "material",
        component: () => import("@/views/module_view/model/Material.vue"),
        meta: { title: "材料", isAuth: false, tabbar: "module" },
      },
      {
        path: "mMachinery_info",
        component: () =>
          import("@/views/module_view/model/Material_Machinery_info.vue"),
        meta: { title: "详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "machinery",
        component: () => import("@/views/module_view/model/Machinery.vue"),
        meta: { title: "机械", isAuth: false, tabbar: "module" },
      },
      {
        path: "knowledge",
        component: () => import("@/views/module_view/model/Knowledge.vue"),
        meta: { title: "知识分享", isAuth: false, tabbar: "module" },
      },
      {
        path: "knowledgeInfo",
        component: () => import("@/views/module_view/model/KnowledgeInfo.vue"),
        meta: { title: "详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "technologyInfo",
        component: () => import("@/views/module_view/model/TechnologyInfo.vue"),
        meta: { title: "详情", isAuth: false, tabbar: "module" },
      },
      {
        path: "noteList",
        component: () => import("@/views/module_view/model/NoteList.vue"),
        meta: { title: "相关笔记", isAuth: false, tabbar: "module" },
      },
      {
        path: "addNote",
        component: () => import("@/views/module_view/model/AddNote.vue"),
        meta: { title: "写笔记", isAuth: false, tabbar: "module" },
      },
      {
        path: "technology",
        component: () => import("@/views/module_view/model/Technology.vue"),
        meta: { title: "施工工艺", isAuth: false, tabbar: "module" },
      },
      {
        path: "discussionArea",
        component: () =>
          import("@/views/module_view/question/DiscussionArea.vue"),
        meta: { title: "讨论区", isAuth: false, tabbar: "" },
      },
      {
        path: "askQuestion",
        component: () => import("@/views/module_view/question/AskQuestion.vue"),
        meta: { title: "", isAuth: false, tabbar: "question" },
      },
      {
        path: "ExpertInfo",
        component: () => import("@/views/module_view/question/ExpertInfo.vue"),
        meta: { title: "专家详情", isAuth: false, tabbar: "question" },
      },
      {
        path: "Ranking",
        component: () => import("@/views/module_view/question/Ranking.vue"),
        meta: { title: "排行榜 ", isAuth: false, tabbar: "question" },
      },
      {
        path: "postInfo",
        component: () => import("@/views/module_view/question/PostInfo.vue"),
        meta: { title: "详情 ", isAuth: false, tabbar: "question" },
      },
      {
        path: "KnowledgeSharing",
        component: () =>
          import("@/views/module_view/question/KnowledgeSharing.vue"),
        meta: { title: "分享详情 ", isAuth: false, tabbar: "question" },
      },
      {
        path: "answer",
        component: () => import("@/views/module_view/question/Answer.vue"),
        meta: { title: "解答 ", isAuth: false, tabbar: "question" },
      },
      {
        path: "myPost",
        component: () => import("@/views/module_view/user/MyPost.vue"),
        meta: { title: "我的帖子 ", isAuth: false, tabbar: "user" },
      },
      {
        path: "myNote",
        component: () => import("@/views/module_view/user/MyNote.vue"),
        meta: { title: "我的笔记 ", isAuth: false, tabbar: "user" },
      },
      {
        path: "myCourse",
        component: () => import("@/views/module_view/user/MyCourse.vue"),
        meta: { title: "我的课程 ", isAuth: false, tabbar: "user" },
      },
    ],
  },

  {
    path: "/StudyIndex",
    name: "StudyIndex",
    meta: { title: "首页" },
    component: () => import("@/views/StudyIndex.vue"),
    redirect: { path: "/Home" },
    children: [
      {
        path: "/Task",
        name: "Task",
        meta: { title: "任务", isAuth: false },
        component: () => import("@/views/hlwy/Task/Task.vue"),
      },
      {
        path: "/Home",
        name: "Home",
        meta: { title: "首页", isAuth: false },
        component: () => import("@/views/hlwy/Home/Home.vue"),
      },
      {
        path: "/Mine",
        name: "Mine",
        meta: { title: "我的", isAuth: false },
        component: () => import("@/views/hlwy/mine/Mine.vue"),
      },
    ],
  },
  {
    path: "/ClassesInfo",
    name: "ClassesInfo",
    meta: { title: "班级详情", isAuth: true },
    component: () => import("@/views/hlwy/Task/ClassesInfo.vue"),
  },
  {
    path: "/Course",
    name: "Course",
    meta: { title: "课件详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/Course.vue"),
  },
  {
    path: "/ClassWare",
    name: "ClassWare",
    meta: { title: "班级的课程详情", isAuth: true },
    component: () => import("@/views/hlwy/Task/ClassWare.vue"),
  },
  {
    path: "/Course1",
    name: "Course1",
    meta: { title: "班级的课件详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/Course1.vue"),
  },
  {
    path: "/CourseWare",
    name: "CourseWare",
    meta: { title: "课程详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/CourseWare.vue"),
  },
  {
    path: "/Cate",
    name: "Cate",
    meta: { title: "分类详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/Cate.vue"),
  },
  {
    path: "/TrainingDynamicsDetail",
    name: "TrainingDynamicsDetail",
    meta: { title: "培训动态详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/TrainingDynamicsDetail.vue"),
  },
  {
    path: "/Text",
    name: "Text",
    meta: { title: "练习", isAuth: true },
    component: () => import("@/views/hlwy/Home/Text.vue"),
  },
  {
    path: "/SpecialTraining",
    name: "SpecialTraining",
    meta: { title: "专题培训", isAuth: true },
    component: () => import("@/views/hlwy/Home/SpecialTraining.vue"),
  },
  {
    path: "/Search1",
    name: "Search1",
    meta: { title: "搜索", isAuth: true },
    component: () => import("@/views/hlwy/Home/Search1.vue"),
  },
  {
    path: "/SearchPage",
    name: "SearchPage",
    meta: { title: "搜索页面", isAuth: true },
    component: () => import("@/views/hlwy/Home/SearchPage.vue"),
  },
  {
    path: "/TrainingZone",
    name: "TrainingZone",
    meta: { title: "培训专区", isAuth: true },
    component: () => import("@/views/hlwy/Home/TrainingZone.vue"),
  },
  {
    path: "/TrainingZoneInfo",
    name: "TrainingZoneInfo",
    meta: { title: "培训专区详情页", isAuth: true },
    component: () => import("@/views/hlwy/Home/TrainingZoneInfo.vue"),
  },
  {
    path: "/TrainingUpdates",
    name: "TrainingUpdates",
    meta: { title: "培训动态", isAuth: true },
    component: () => import("@/views/hlwy/Home/TrainingUpdates.vue"),
  },
  {
    path: "/TrainingPlan",
    name: "TrainingPlan",
    meta: { title: "培训计划", isAuth: true },
    component: () => import("@/views/hlwy/Home/TrainingPlan.vue"),
  },
  {
    path: "/TraningPlanInfo",
    name: "TraningPlanInfo",
    meta: { title: "培训计划详情", isAuth: true },
    component: () => import("@/views/hlwy/Home/TraningPlanInfo.vue"),
  },
  {
    path: "/CourseMid",
    name: "CourseMid",
    meta: { title: "课程中心", isAuth: true },
    component: () => import("@/views/hlwy/Home/CourseMid.vue"),
  },
  {
    path: "/CourseCenter",
    name: "CourseCenter",
    meta: { title: "课程列表", isAuth: true },
    component: () => import("@/views/hlwy/Home/CourseCenter.vue"),
  },
  {
    path: "/CourseWareInterval",
    name: "CourseWareInterval",
    meta: { title: "课程计时", isAuth: true },
    component: () => import("@/views/hlwy/Home/CourseWareInterval.vue"),
  },
  {
    path: "/CoursePractice",
    name: "CoursePractice",
    component: () => import("@/views/hlwy/Home/CoursePractice.vue"),
    meta: { title: "课程随堂练习", system: "前端", isAuth: true },
  },
  {
    path: "/CoursePracticePreview",
    name: "CoursePracticePreview",
    component: () => import("@/views/hlwy/Home/CoursePracticePreview.vue"),
    meta: { title: "课程随堂练习", system: "前端", isAuth: true },
  },
  {
    path: "/CourseAssess",
    name: "CourseAssess",
    component: () => import("@/views/hlwy/Home/CourseAssess.vue"),
    meta: { title: "课程评估", system: "前端", isAuth: true },
  },
  {
    path: "/CourseAssessmanger",
    name: "CourseAssessmanger",
    component: () => import("@/views/hlwy/Home/CourseAssessmanger.vue"),
    meta: { title: "教学管理课程评估", system: "前端", isAuth: true },
  },
  {
    path: "/MineCollect",
    name: "MineCollect",
    component: () => import("@/views/hlwy/mine/MyCollect.vue"),
    meta: { title: "我的收藏", system: "前端", isAuth: true },
  },
  {
    path: "/MineCourse",
    name: "MineCourse",
    component: () => import("@/views/hlwy/mine/MyCourse.vue"),
    meta: { title: "我的课程", system: "前端", isAuth: true },
  },

  {
    path: "/MineExam",
    name: "MineExam",
    component: () => import("@/views/hlwy/mine/MyExam.vue"),
    meta: { title: "我的考试", system: "前端", isAuth: true },
  },
  {
    path: "/MineRemark",
    name: "MineRemark",
    component: () => import("@/views/hlwy/mine/MyRemark.vue"),
    meta: { title: "我的评论", system: "前端", isAuth: true },
  },
  {
    path: "/MineQuestion",
    name: "MineQuestion",
    component: () => import("@/views/hlwy/mine/MyQuestion.vue"),
    meta: { title: "我的问卷", system: "前端", isAuth: true },
  },
  {
    path: "/MineWrong",
    name: "MineWrong",
    component: () => import("@/views/hlwy/mine/MyWrong.vue"),
    meta: { title: "我的错题本", system: "前端", isAuth: true },
  },
  {
    path: "/MineWrongInfo",
    name: "MineWrongInfo",
    component: () => import("@/views/hlwy/mine/MineWrongInfo.vue"),
    meta: { title: "错题本详情", system: "前端", isAuth: true },
  },
  {
    path: "/MyWrongtext",
    name: "MyWrongtext",
    component: () => import("@/views/hlwy/mine/MyWrongtext.vue"),
    meta: { title: "单独错题详情", system: "前端", isAuth: true },
  },
  {
    path: "/MineIntegral",
    name: "MineIntegral",
    component: () => import("@/views/hlwy/mine/MyIntegral.vue"),
    meta: { title: "积分详情", system: "前端", isAuth: true },
  },
  {
    path: "/ApplyCenter",
    name: "ApplyCenter",
    component: () => import("@/views/hlwy/mine/ApplyCenter.vue"),
    meta: { title: "审核中心", system: "前端", isAuth: true },
  },
  {
    path: "/CourseApplyCenter",
    name: "CourseApplyCenter",
    component: () => import("@/views/hlwy/mine/CourseApplyCenter.vue"),
    meta: { title: "课件审核详情", system: "前端", isAuth: true },
  },
  {
    path: "/QuesApplyCenter",
    name: "QuesApplyCenter",
    component: () => import("@/views/hlwy/mine/QuesApplyCenter.vue"),
    meta: { title: "试题审核详情", system: "前端", isAuth: true },
  },
  {
    path: "/ApprovaleApplyCenter",
    name: "ApprovaleApplyCenter",
    component: () => import("@/views/hlwy/mine/ApprovaleApplyCenter.vue"),
    meta: { title: "问卷审核详情", system: "前端", isAuth: true },
  },
  {
    path: "/TeachManager",
    name: "TeachManager",
    component: () => import("@/views/hlwy/mine/TeachManager.vue"),
    meta: { title: "教学管理", system: "前端", isAuth: true },
  },
  {
    path: "/MineInfo",
    name: "MineInfo",
    component: () => import("@/views/hlwy/mine/MineInfo.vue"),
    meta: {
      title: "教学管理人员班级详情",
      system: "前端",
      isAuth: true
    },
  },
  {
    path: "/MineCourseInfo",
    name: "MineCourseInfo",
    component: () => import("@/views/hlwy/mine/MineCourseInfo.vue"),
    meta: {
      title: "教学管理课程详情",
      system: "前端",
      isAuth: true
    },
  },
  {
    path: "/Survey",
    name: "Survey",
    component: () => import("@/views/hlwy/mine/Survey.vue"),
    meta: {
      title: "教学管理班级详情",
      system: "前端",
      isAuth: true,
      keepAlive: false,
    },
  },
  {
    path: "/Assess",
    name: "Assess",
    component: () => import("@/views/hlwy/Task/Assess.vue"),
    meta: { title: "评估问卷", system: "前端", isAuth: true },
  },
  {
    path: "/ClassAssess",
    name: "ClassAssess",
    component: () => import("@/views/hlwy/Task/ClassAssess.vue"),
    meta: { title: "班级评估问卷", system: "前端", isAuth: true },
  },
  {
    path: "/showFile",
    name: "showFile",
    meta: { isAuth: true, title: "查看文件" },
    component: () => import("@/components/showFile.vue"),
  },
  {
    path: "/ExamPrelook",
    name: "ExamPrelook",
    component: () => import("@/views/hlwy/Task/ExamPrelook.vue"),
    meta: { title: "考试封面", system: "前端", isAuth: true },
  },
  {
    path: "/ExamStart",
    name: "ExamStart",
    component: () => import("@/views/hlwy/Task/ExamStart.vue"),
    meta: { title: "考试开始", system: "前端", isAuth: true },
  },
  {
    path: "/ExamResult",
    name: "ExamResult",
    component: () => import("@/views/hlwy/Task/ExamResult.vue"),
    meta: { title: "考试结果", system: "前端", isAuth: true },
  },
  {
    path: "/ExamResult1",
    name: "ExamResult1",
    component: () => import("@/views/hlwy/Task/ExamResult1.vue"),
    meta: { title: "考试结果1", system: "前端", isAuth: true },
  },
  {
    path: "/ExamResultInfo",
    name: "ExamResultInfo",
    component: () => import("@/views/hlwy/Task/ExamResultInfo.vue"),
    meta: { title: "考试结果页", system: "前端", isAuth: true },
  },
  {
    path: "/ClassPractice",
    name: "ClassPractice",
    component: () => import("@/views/hlwy/Task/ClassPractice.vue"),
    meta: { title: "班级随堂练习", system: "前端", isAuth: true },
  },
  {
    path: "/ClassPracticePreview",
    name: "ClassPracticePreview",
    component: () => import("@/views/hlwy/Task/ClassPracticePreview.vue"),
    meta: { title: "班级随堂练习答题记录", system: "前端", isAuth: true },
  },
  {
    path: "/ClassPracticeCover",
    name: "ClassPracticeCover",
    component: () => import("@/views/hlwy/Task/ClassPracticeCover.vue"),
    meta: { title: "随堂练习封面", system: "前端", isAuth: true },
  },
  {
    path: "/CoursePracticeCover",
    name: "CoursePracticeCover",
    component: () => import("@/views/hlwy/Task/CoursePracticeCover.vue"),
    meta: { title: "课程随堂练习封面", system: "前端", isAuth: true },
  },
  {
    path: "/video",
    name: "video",
    component: () => import("@/views/hlwy/Task/video.vue"),
    meta: { title: "视频播放", system: "前端", isAuth: true },
  },
  {
    path: "/video1",
    name: "video1",
    component: () => import("@/views/hlwy/Task/video1.vue"),
    meta: { title: "专区文件预览", system: "前端", isAuth: true },
  },
  {
    path: "/video2",
    name: "video2",
    component: () => import("@/views/hlwy/Task/video2.vue"),
    meta: { title: "班级课件文件预览", system: "前端", isAuth: true },
  },
  {
    path: "/video3",
    name: "video3",
    component: () => import("@/views/hlwy/Task/video3.vue"),
    meta: { title: "分享附件预览", system: "前端", isAuth: true },
  },
  {
    path: "/CKnowledgeSharing",
    name: "CKnowledgeSharing",
    component: () => import("@/views/hlwy/Home/CKnowledgeSharing.vue"),
    meta: { title: "知识分享", system: "前端", isAuth: true },
  },
  {
    path: "/CKonwleageInfo",
    name: "CKonwleageInfo",
    component: () => import("@/views/hlwy/Home/CKonwleageInfo.vue"),
    meta: { title: "知识分享详情", system: "前端", isAuth: true },
  },
  {
    path: "/WriteShare",
    name: "WriteShare",
    component: () => import("@/views/hlwy/Home/WriteShare.vue"),
    meta: { title: "写分享", system: "前端", isAuth: true },
  },
  {
    path: "/talk",
    name: "talk",
    component: () => import("@/views/hlwy/Home/talk.vue"),
    meta: { title: "话题", system: "前端", isAuth: true },
  },
  {
    path: "/MineShare",
    name: "MineShare",
    component: () => import("@/views/hlwy/mine/MineShare.vue"),
    meta: { title: "我的分享", system: "前端", isAuth: true },
  },
  {
    path: "/MineCertificate",
    name: "MineCertificate",
    component: () => import("@/views/hlwy/mine/MineCertificate.vue"),
    meta: { title: "我的证书", system: "前端", isAuth: true },
  },
  {
    path: "/MyExamGrading",
    name: "MyExamGrading",
    component: () => import("@/views/hlwy/mine/MyExamGrading.vue"),
    meta: { title: "考试批阅", system: "前端", isAuth: true },
  },
  {
    path: "/MyExamApply",
    name: "MyExamApply",
    component: () => import("@/views/hlwy/mine/MyExamApply.vue"),
    meta: { title: "考试批阅详情", system: "前端", isAuth: true },
  },
  {
    path: "/MyExamApplyLook",
    name: "MyExamApplyLook",
    component: () => import("@/views/hlwy/mine/MyExamApplyLook.vue"),
    meta: { title: "考试批阅查看", system: "前端", isAuth: true },
  },
  {
    path: "/PreviewCertificate",
    name: "PreviewCertificate",
    component: () => import("@/views/hlwy/mine/PreviewCertificate.vue"),
    meta: { title: "预览证书", system: "前端", isAuth: true },
  },
  {
    path: "/Login",
    name: "Login",
    meta: { title: "登录" },
    component: () => import("@/views/Login.vue"),
  },
  /**
   * 路由重定向
   */
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  $store.commit("personData/changeTitle", to.meta.title);
  $store.commit("personData/changeClass", to.path);
  $store.commit("personData/changeTabbar", to.meta.tabbar);
  const token = localStorage.getItem("token"); //读取token
  // 初始化知识问答讨论区的切换类型
  if (from.path == "/gateway/question") {
    localStorage.setItem("activeType", "0");
  }
  // 初始化我的帖子顶部切换类型
  if (from.path == "/gateway/user") {
    localStorage.setItem("myPostTitle", "解答");
  }

  if (to.meta.isAuth) {
    //判断是否需要鉴权
    if (token === null) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
