import axios from 'axios';
import { showNotify  } from 'vant';
import $router from '@/router'
let tokenVal: any = null;  //定义token的值
let refreshToken:any = null

// if(window.location.href.includes('ddToken')){
// 	console.log("进入钉钉ddtoken");
	
// 	// 钉钉微应用内跳转浏览器进行相关本地存储token等
// 	let urlObj = getUrlParams(window.location.href)
// 	// url传参中文乱码通过decodeURI解码
// 	localStorage.setItem('token',urlObj['ddToken'])
// 	localStorage.setItem('RefreshToken',urlObj['RefreshToken'])
// 	alert(`打印钉钉登录对象${urlObj['ddToken']}`);
// }
// // js获取路径参数的方法
// function getUrlParams(url:any) {
//     // 通过 ? 分割获取后面的参数字符串
//     let urlStr = url?.split('?')[1]
//     // 创建空对象存储参数
//     let obj:any = {};
//     // 再通过 & 将每一个参数单独分割出来
//     let paramsArr = urlStr?.split('&')
//     for(let i = 0,len = paramsArr?.length;i < len;i++){
//         // 再通过 = 将每一个参数分割为 key:value 的形式
//         let arr = paramsArr[i]?.split('=')
//         obj[arr[0]] = arr[1];
//     }
//     return obj
// }

checkToken();
function checkToken(){	//获取token的值
	if(!localStorage.getItem('token')){
		//获取UE的token
		if(sessionStorage.getItem('token') == undefined){
			tokenVal = "";
		}else{
			tokenVal = sessionStorage.getItem('token')
		}
	}else{
		if(localStorage.getItem('token') == null){
			tokenVal = "";
		}else{
			tokenVal = localStorage.getItem('token');
		}
	}
	if(!localStorage.getItem('RefreshToken')){
		//获取UE的token
		if(sessionStorage.getItem('RefreshToken') == undefined){
			refreshToken = "";
		}else{
			refreshToken = sessionStorage.getItem('RefreshToken')
		}
	}else{
		if(localStorage.getItem('RefreshToken') == null){
			refreshToken = "";
		}else{
			refreshToken = localStorage.getItem('RefreshToken');
		}
	}
}

const errorBack = () => {
	$router.push("/login")
}

// 创建一个 axios 实例
const service = axios.create({
	baseURL: '', // 所有的请求地址前缀部分
	timeout: 60000, // 请求超时时间毫秒
	withCredentials: true, // 异步请求携带cookie
	headers: {
		// 设置后端需要的传参类型
		'X-Requested-With': 'XMLHttpRequest',
		'menuUri': window.location.pathname
	},
})

let loadingInstance: any = null;
localStorage.setItem('showLoad', '1')
// // 判断是否需要刷新
let totalTime: number = 2 * 60;
// if (localStorage.getItem('updataToken') == '1') {
	let clock = setInterval(() => {
		totalTime--;
		if (totalTime == 0) {
			localStorage.setItem(('showLoad'), '0')
			totalTime = 2 * 60;
			//当倒计时小于0时清除定时器
			
			axios.post(`/api/Login/GetNewToken`,{},{headers:{Authorization:'Bearer '+ refreshToken}}).then((response)=>{
				if(response.data.ResultCode === '0000'){
					localStorage.setItem("token", response.data.Token);
					localStorage.setItem("RefreshToken", response.data.RefreshToken);
					sessionStorage.setItem("token", response.data.Token);
					sessionStorage.setItem("RefreshToken", response.data.RefreshToken);
				}else{
					clearInterval(clock); //关闭
					showNotify({
						type: 'danger',
						message: '无效/过期的会话，请重新登录',
						duration:1000,
						onClose:errorBack
					});
				}
			})
		}
	}, 1000);

// 添加请求拦截器
service.interceptors.request.use( async function (config:any) {
		if(config.url === '/api/Login/Login'){
			if (tokenVal !== null && tokenVal !== '') {
				config.headers.Authorization ='Bearer '+ tokenVal;
			}
			return config
		}else if(config.url === "/api/Login/DingLogin" || config.url === "/api/Login/DingAuthLogin"){
			// 钉钉登录
			if (tokenVal !== null && tokenVal !== '') {
				config.headers.Authorization ='Bearer '+ localStorage.getItem('token');
			}
			return config
		}else {
			checkToken();
			var strings = tokenVal.split(".");//通过split()方法将token转为字符串数组
			var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
			const tokenData = userinfo.exp*1000;
			const now = new Date().getTime();
			// if(tokenData - now<=5000 && tokenData - now > 0){
			if(tokenData - now <= 50000 ){
				const response = await axios.post(
					`/api/Login/GetNewToken`,
					{},
					{headers:{Authorization:'Bearer '+ refreshToken}}
				)
				if(response.data.ResultCode === '0000'){
					config.headers.Authorization ='Bearer '+ response.data.Token;
					localStorage.setItem("token", response.data.Token);
					localStorage.setItem("RefreshToken", response.data.RefreshToken);
					sessionStorage.setItem("token", response.data.Token);
					sessionStorage.setItem("RefreshToken", response.data.RefreshToken);
					return config
				}else {
					const response = await axios.post(
						`/api/Login/GetNewToken`,
						{},
						{headers:{Authorization:'Bearer '+ refreshToken}}
					)
					if(response.data.ResultCode === '0000'){
						config.headers.Authorization ='Bearer '+ response.data.Token;
						localStorage.setItem("token", response.data.Token);
						localStorage.setItem("RefreshToken", response.data.RefreshToken);
						sessionStorage.setItem("token", response.data.Token);
						sessionStorage.setItem("RefreshToken", response.data.RefreshToken);
						return config
					}else {
						errorBack()
					}
				}
			}else {
				// 在发送请求之前做些什么
				if (localStorage.getItem('showLoad') !== '0') {
					const test = window.location.href;
					const tage = /WEB/g
					if(!tage.test(test)){
						// loadingInstance = ElLoading.service({ fullscreen: true }) //加载转圈
					}
				}
				checkToken();
				if (tokenVal !== null && tokenVal !== '') {
					config.headers.Authorization ='Bearer '+ tokenVal;
				}
				return config
			}
		}
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	function (response) {
		if (loadingInstance !== null) {
			loadingInstance.close()
		}
		// 2xx 范围内的状态码都会触发该函数。
		// dataAxios 是 axios 返回数据中的 data
		const dataAxios = response.data
		const code = dataAxios.reset
		checkToken()
		return dataAxios
	},
	 async function (error) {
		if (loadingInstance !== null) {
			loadingInstance.close()
		}

		//超时处理
		if (error.config) {
			showNotify({
				type: 'danger',
				message: '连接超时',
				duration:1000,
				onClose:()=>{
						return Promise.reject(error)
				}
			});
		}

		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		if (error.response.status == 400) {
			showNotify({
				type: 'danger',
				message:  error.response.data.message,
				duration:2000,
			});
		} else if (error.response.status == 401) {

			const response = await axios.post(
				`/api/Login/GetNewToken`,
				{},
				{headers:{Authorization:'Bearer '+ refreshToken}}
			)
			if(response.data.ResultCode === '0000'){
				localStorage.setItem("token", response.data.Token);
				localStorage.setItem("RefreshToken", response.data.RefreshToken);
				sessionStorage.setItem("token", response.data.Token);
				sessionStorage.setItem("RefreshToken", response.data.RefreshToken);
			}else {
				showNotify({
					type: 'danger',
					message: '无效/过期的会话，请重新登录',
					duration:2000,
					onClose:()=>{
						errorBack()
					}
				});
			}

		} else if (error.response.status == 404) {
			showNotify({
				type: 'danger',
				message: '网络连接失败，请检查网络或联系管理员！',
				duration:2000
			});
		} else if (error.response.status == 405) {
			showNotify({
				type: 'danger',
				message: '网络连接失败，请检查网络或联系管理员！',
				duration:2000
			});
		} else if (error.response.status == 500) {
			showNotify({
				type: 'danger',
				message: '服务连接失败，请联系管理员!',
				duration:2000
			});
		}

		return Promise.reject(error)
	}
)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url: string, params: any) => {
	return new Promise((resolve, reject) => {
		service.get(url, {
			params: params
		}).then(res => {
			resolve(res)
		}).catch(err => {
			const test = /GetNewToken/g;
			if(test.test(err.request.responseURL)){
				if(err.response.status===401){
					errorBack()
				}
			}
			reject(err.data)
		})
	})
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url: string, params: any) => {
	return new Promise((resolve, reject) => {
		service.post(url, params)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

/**
 * put
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $put = (url: string, params: any) => {
	return new Promise((resolve, reject) => {
		service.put(url, params)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $delete = (url: string, params: any) => {
	return new Promise((resolve, reject) => {
		service.delete(url, params)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err.data)
			})
	})
}

export default {
	install: (app: any) => {
		app.config.globalProperties['$get'] = $get;
		app.config.globalProperties['$post'] = $post;
		app.config.globalProperties['$put'] = $put;
		app.config.globalProperties['$delete'] = $delete;
	}
}
