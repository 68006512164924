<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<style lang="less">
html,
body,
#app {
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  cursor: default;
  background-color: #F4F6FA;
  font-size: 12px;
}

.tox-tinymce-aux {
  z-index: 9999 !important;
}

* {
  box-sizing: border-box;
}
@font-face{
  font-family:Alimama_ShuHeiTi_Bold; // 自定义名称，无需引号
  src:url('./../public/font/Alimama_ShuHeiTi_Bold.ttf') // 字体文件路径，支持本地文件，或字体文件链接地址
}
@font-face {
  font-family: 'iconfont';
  src: url('./../public/font/iconfont.woff2?t=1686799712375') format('woff2'),
  url('./../public/font/iconfont.woff?t=1686799712375') format('woff'),
  url('./../public/font/iconfont.ttf?t=1686799712375') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>