import "@babel/polyfill"; //(一定要在最上面，第一行)
import Es6Promise from "es6-promise";
require("es6-promise").polyfill();
Es6Promise.polyfill();
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./request/http";  //封装axios
import 'amfe-flexible';
import Vant from 'vant' //全局引用element Plus
import '../node_modules/vant/lib/index.css'

// 钉钉调试微应用工具
import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
initDingH5RemoteDebug();


const app = createApp(App)
app.use(store).use(router).use(http).use(Vant);
app.mount("#app")